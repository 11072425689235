import * as React from "react"
import { Container, Button, Col } from "react-bootstrap"
import  FaMapMarker  from "../../../images/faMapMarker";
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql, Link } from "gatsby"


 



 
function AllTours({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query Tours{
            allGraphCmsItemTours {
                edges {
                  node {
                    id
                    tourTitle
                    tourStatus
                    tourPrice
                    tourLocation
                    slug
                    tourDescription {
                      html
                      text
                      raw
                    }
                    tourImage {
                      id
                      imageAltText
                      imageTitleText
                      galleryImage {
                        gatsbyImageData(quality: 60)
                        url
                      }
                    }
                  }
                }
              }
           }                     
        `}
                
        render={data => (
        
    <div className="tours">
        <div className="welcome-bg">

        </div>
        <div className="wrapper">
          <div className="row">
            <div className="col-sm-8">
            <div className="title">
                  <span>Perfect For You</span>
                  <h1>Our Inland Tours</h1>
                  
            </div>
            </div>
            <div className="col-sm-4 cta">
            <a href="/tours"><button id="primary-button" type="button" >View All Tours</button></a>
            </div>
          </div>
         

         <div className="gallery">
           <div id="bg-photos"></div>
            <div className="image-gallery">
            
              <div className="photos">
             {data.allGraphCmsItemTours .edges.map(({ node: country }) => (
              <Link  to={`/${country.slug}`}>
                <div  key={country.id}>
                 {country.tourStatus.includes("Popular")
                 ?
                 
                   <div className="main-content"  key={country.id}>
                        <div className="data-wrapper">
                        
                            <div className="estate-type">
                                <div className="image-field">
                                <img src={country.tourImage.galleryImage.url} 
                                     
                                     alt={country.tourImage.imageAltText} />
                                    <div className="overlay">
                                    </div>

                                   <div className="price">
                                     {country.tourPrice}
                                    </div>

                                </div>
                                <div className="desc-field">
                                    <div className="content">
                                        <Container> 
                                        <div className="block">
                                               
                                          <div><h2>{country.tourTitle}</h2></div>
                                          <div><span><FaMapMarker />{country.tourLocation}</span></div>
                                               
                             
                                          {/*<a href="">View Tour</a>*/}
                                                  
                                           
                                       </div>
                                        </Container>
                                    </div>
                                </div>
                            </div> 
                     

                        </div>
                        
                    </div> 
                    :
                    <></>
                    }
                </div>
                </Link>
                ))}
                </div>
            
            </div>
           </div>
        </div>

        <div className="wrapper sea">
          <div className="row">
            <div className="col-sm-8">
            <div className="title">
                  <span>Explore our all-inclusive Sea Adventures.</span>
                  <h1>We also offer Sea Tours!</h1>
                  
            </div>
            </div>
            <div className="col-sm-4 cta">
            <a href="/tours"><button id="primary-button" type="button" >View All Tours</button></a>
            </div>
          </div>
         

         <div className="gallery">
           <div id="bg-photos"></div>
            <div className="image-gallery">
            
              <div className="photos">
             {data.allGraphCmsItemTours .edges.map(({ node: country }) => (
              <Link  to={`/${country.slug}`}>
                <div  key={country.id}>
                 {country.tourStatus.includes("sea")
                   ?
                 
                   <div className="main-content"  key={country.id}>
                        <div className="data-wrapper">
                        
                            <div className="estate-type">
                                <div className="image-field">
                                    
                                    <img src={country.tourImage.galleryImage.url} 
                                     
                                        alt={country.tourImage.imageAltText} />

                                    <div className="overlay">
                                    </div>

                                   <div className="price">
                                     {country.tourPrice}
                                    </div>

                                </div>
                                <div className="desc-field">
                                    <div className="content">
                                        <Container> 
                                        <div className="block">
                                               
                                          <div><h2>{country.tourTitle}</h2></div>
                                          <div><span><FaMapMarker />{country.tourLocation}</span></div>
                                               
                             
                                          {/*<a href="">View Tour</a>*/}
                                                  
                                           
                                       </div>
                                        </Container>
                                    </div>
                                </div>
                            </div> 
                     

                        </div>
                        
                    </div> 
                    :
                    <></>
                    }
                </div>
                </Link>
                ))}
                </div>
            
            </div>
           </div>
        </div>
      
	</div>
    

    )}
      />
    );
}


export default AllTours;


